import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { cn } from '@notacami/core/css';
import { DetailedNoteDetect } from '../../../../../services/note-played/types';
import { ServicesContext } from '../../../../../services/services.context';

export function TunerCentsOffThumb() {
    const { notePlayed } = useContext(ServicesContext);
    const resetTimeoutIdRef = useRef<number>();
    const [currentNotePlayed, setNotePlayed] = useState<DetailedNoteDetect>();

    const handleDetailedNoteDetect = useCallback(
        ({ note }: { note: DetailedNoteDetect }) => {
            setNotePlayed(note);

            if (resetTimeoutIdRef.current) {
                clearTimeout(resetTimeoutIdRef.current);
            }
            resetTimeoutIdRef.current = window.setTimeout(resetData, 500);
        },
        [],
    );

    const resetData = () => {
        setNotePlayed(undefined);
    };

    useEffect(() => {
        notePlayed.on('detailed-note-detect', handleDetailedNoteDetect);

        return () => {
            notePlayed.off('detailed-note-detect', handleDetailedNoteDetect);
        };
    }, [handleDetailedNoteDetect]);

    const leftPercentage = currentNotePlayed?.centsOff
        ? currentNotePlayed.centsOff + 50
        : 50;

    const boundedLeftPercentage = Math.min(Math.max(leftPercentage, 0), 100);

    return (
        <div className="px-1">
            <div className="relative w-full h-0">
                <div
                    style={{ left: boundedLeftPercentage + '%' }}
                    className="transition-all absolute bg-white -ml-1 top-4 w-2 h-4 z-20 border-white border rounded-full shadow-white shadow-sm flex items-center justify-center"
                >
                    <div
                        className={cn(
                            currentNotePlayed?.centsOff
                                ? 'bg-primary'
                                : 'bg-neutral-500',
                            'w-1 h-2 rounded-full',
                        )}
                    ></div>
                </div>
            </div>
        </div>
    );
}

import { Book } from 'lucide-react';
import { MDX_COMPONENTS } from '@notacami/ui';
import { useLongText } from '../../services/i18n/use-long-text';
import { INTERVALS_SEARCH_PARAM, PageUrls } from '../../utils/routes.contants';
import { ExpandableCard } from '../../components/ui/expandable-card/expandable-card';
import { createSimpleExpandableCardTitle } from '../../components/ui/expandable-card/title';
import { useShowTabBar } from '../../components/ui/tab-bar.hooks';
import { useTranslation } from '../../hooks/use-translation';
import { ThematicPageLayout } from '../../components/business/thematic-page-layout';
import { ThematicPageLinkItem } from '../../components/business/thematic-page-link-item';
import { ThematicPageContentTitle } from '../../components/business/thematic-page-content-title';

export function IntervalsPage() {
    useShowTabBar();

    const { t } = useTranslation();

    const intervalsLongText = useLongText('intervals');

    return (
        <ThematicPageLayout thematic="intervals">
            <ExpandableCard
                isDark
                title={createSimpleExpandableCardTitle(
                    <Book />,
                    intervalsLongText.frontmatter.title,
                )}
                titleText={intervalsLongText.frontmatter.title}
            >
                <intervalsLongText.component components={MDX_COMPONENTS} />
            </ExpandableCard>

            <ThematicPageContentTitle>
                {t('interval-name', {
                    quality: 'M',
                    number: '3',
                })}
            </ThematicPageContentTitle>

            <div className="vertical-content-distribution-sm">
                <ThematicPageLinkItem
                    to={`${PageUrls.VISUALIZE_INTERVAL_POSITIONS}?${INTERVALS_SEARCH_PARAM}=3M`}
                    thematic="intervals"
                    type="visualization"
                    isExercise={false}
                    specificLabels={[
                        t('interval-name', {
                            quality: 'M',
                            number: '3',
                        }),
                    ]}
                >
                    {t(`pages.visualize_interval_positions.title`)}
                </ThematicPageLinkItem>
            </div>

            <ThematicPageContentTitle>
                {`${t('interval-number.3')}, ${t('interval-name', {
                    quality: 'P',
                    number: '5',
                })} ${t('and')} ${t('interval-name', {
                    quality: 'P',
                    number: '8',
                })}`}
            </ThematicPageContentTitle>

            <div className="vertical-content-distribution-sm">
                <ThematicPageLinkItem
                    to={`${PageUrls.FIND_THE_NOTE_FROM_ROOT_AND_INTERVAL}?${INTERVALS_SEARCH_PARAM}=3m,3M,5P,8P`}
                    thematic="intervals"
                    type="quiz-exercise"
                    isExercise
                    specificLabels={[
                        t('interval-number.3'),
                        t('interval-name', {
                            quality: 'P',
                            number: '5',
                        }),
                        t('interval-name', {
                            quality: 'P',
                            number: '8',
                        }),
                    ]}
                >
                    {t(`pages.FIND_THE_NOTE_FROM_ROOT_AND_INTERVAL.title`)}
                </ThematicPageLinkItem>
                <ThematicPageLinkItem
                    to={`${PageUrls.FIND_THE_INTERVAL_ON_THE_NECK}?${INTERVALS_SEARCH_PARAM}=3m,3M,5P,8P`}
                    thematic="intervals"
                    type="fretboard-exercise"
                    isExercise
                    specificLabels={[
                        t('interval-number.3'),
                        t('interval-name', {
                            quality: 'P',
                            number: '5',
                        }),
                        t('interval-name', {
                            quality: 'P',
                            number: '8',
                        }),
                    ]}
                >
                    {t('pages.FIND_THE_INTERVAL_ON_THE_NECK.title')}
                </ThematicPageLinkItem>
            </div>
            <ThematicPageContentTitle>
                {t('all-intervals')}
            </ThematicPageContentTitle>
            <div className="vertical-content-distribution-sm">
                <ThematicPageLinkItem
                    to={PageUrls.FIND_THE_NOTE_FROM_ROOT_AND_INTERVAL}
                    thematic="intervals"
                    type="quiz-exercise"
                    isExercise
                    specificLabels={[t('all-intervals')]}
                >
                    {t(`pages.FIND_THE_NOTE_FROM_ROOT_AND_INTERVAL.title`)}
                </ThematicPageLinkItem>
                <ThematicPageLinkItem
                    to={PageUrls.FIND_THE_INTERVAL_ON_THE_NECK}
                    thematic="intervals"
                    type="fretboard-exercise"
                    isExercise
                    specificLabels={[t('all-intervals')]}
                >
                    {t(`pages.FIND_THE_INTERVAL_ON_THE_NECK.title`)}
                </ThematicPageLinkItem>
            </div>
        </ThematicPageLayout>
    );
}

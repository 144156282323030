import Emittery from 'emittery';
import { NoteEvent } from '@notacami/core/composer';
import { Musician } from '../musician/musician';
import { PartId } from '../sequence/sequence.constants';
import { FretboardNoteDetails } from '../fretboard';
import { computeNotesToPlay, EventInPart } from '../composer';
import { SoundPlayerEvents } from './sound-player.types';

export class SoundPlayer extends Emittery<SoundPlayerEvents> {
    constructor(private readonly musician: Musician) {
        super();
        this.bindMusicianEvents();
    }

    private bindMusicianEvents() {
        this.musician.on('note-start', ({ partId, noteStartEvent }) => {
            this.emit('note-start', {
                partId,
                noteStartEvent,
            });
        });
        this.musician.on('note-end', ({ partId, noteEndEvent }) => {
            this.emit('note-end', { partId, noteEndEvent });
        });
        this.musician.on('part-start', ({ partId }) => {
            this.emit('part-start', { partId });
        });
        this.musician.on('part-end', ({ partId }) => {
            this.emit('stop');
            this.emit('part-end', { partId });
        });
        this.musician.on(
            'group-note-start',
            ({ partId, groupNoteStartEvent }) => {
                this.emit('group-note-start', { partId, groupNoteStartEvent });
            },
        );
        this.musician.on('group-note-end', ({ partId, groupNoteEndEvent }) => {
            this.emit('group-note-end', { partId, groupNoteEndEvent });
        });
    }

    public async playSequence(
        partId: PartId,
        noteEvents: NoteEvent[],
        fretboardNoteDetails: FretboardNoteDetails,
    ) {
        const notesToPlay = computeNotesToPlay(
            noteEvents,
            fretboardNoteDetails,
        );
        this.musician.playNotes(partId, notesToPlay);
    }

    public async playEventsInPart(partId: PartId, eventsInPart: EventInPart[]) {
        this.musician.playNotes(partId, eventsInPart);
    }

    public stop() {
        this.emit('stop');
        this.musician.stop();
    }
}

import { BookText, CornerDownLeft } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Thematic } from '@notacami/core/thematic';
import {
    AppLanguage,
    LessonLocalizedText,
    getTextByLocalizedTextAndCurrentLanguage,
} from '@notacami/core/i18n';
import { LessonType } from '@notacami/core/lesson';
import { Button } from '@notacami/ui';
import { getColorVariantByThematic } from '@notacami/core/design';
import { PageUrls } from '../../../../../utils/routes.contants';
import { PageLayout } from '../../../page-layout';
import { useTranslation } from '../../../../../hooks/use-translation';
import { GradientDivider } from '../../../../ui/gradient-divider';
import { ExerciseConfigurationTitle } from '../../../exercise/states/configuration/exercise-configuration-title';
import { ExpandableCard } from '../../../../ui/expandable-card/expandable-card';
import { createSimpleExpandableCardTitle } from '../../../../ui/expandable-card/title';

type LessonIntroductionProps = {
    currentLanguage: AppLanguage;
    description: LessonLocalizedText;
    disableStartButton: boolean;
    estimatedDuration: number;
    lessonType: LessonType;
    mainThematic: Thematic;
    name: LessonLocalizedText;
    onStartClick: () => void;
};

export function LessonIntroduction({
    currentLanguage,
    description,
    disableStartButton,
    estimatedDuration,
    lessonType,
    mainThematic,
    name,
    onStartClick,
}: LessonIntroductionProps) {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <PageLayout
            leftAction={
                pathname !== PageUrls.LESSON_CREATION ? (
                    <Link to={PageUrls.HOME} title={t('button.back')}>
                        <CornerDownLeft size={30} />
                    </Link>
                ) : null
            }
            content={
                <div className="vertical-content-distribution-lg justify-center items-stretch min-h-full">
                    <ExerciseConfigurationTitle
                        thematic={mainThematic}
                        lessonType={lessonType}
                        specificLabels={[t(`thematic.${mainThematic}`)]}
                    >
                        {getTextByLocalizedTextAndCurrentLanguage(
                            name,
                            currentLanguage,
                        )}
                    </ExerciseConfigurationTitle>
                    <div className="text-center text-sm">
                        <Trans
                            i18nKey="estimated-duration"
                            values={{ estimatedDuration }}
                            components={[<strong key={0} />]}
                        />
                    </div>
                    <GradientDivider />
                    <ExpandableCard
                        key={getTextByLocalizedTextAndCurrentLanguage(
                            description,
                            currentLanguage,
                        )}
                        title={createSimpleExpandableCardTitle(
                            <BookText />,
                            t('description'),
                        )}
                        titleText={t('description')}
                    >
                        {getTextByLocalizedTextAndCurrentLanguage(
                            description,
                            currentLanguage,
                        )}
                    </ExpandableCard>
                </div>
            }
            bottomAction={
                <Button
                    type="submit"
                    className="w-full"
                    size="lg"
                    pending={false}
                    colorVariant={getColorVariantByThematic(mainThematic)}
                    onClick={onStartClick}
                    disabled={disableStartButton}
                >
                    {t('button.start')}
                </Button>
            }
        />
    );
}

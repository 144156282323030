import { AnimatePresence } from 'framer-motion';
import { ColorVariant } from '@notacami/core/design';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';
import { useTranslation } from '../../../../../hooks/use-translation';
import { TranslationKey } from '../../../../../services/i18n/translations.types';
import { BadgeMotion } from '../../../../ui/badge-motion';
import { PartId } from '../../../../../services/sequence/sequence.constants';
import { DerivedTeacherPlayingStep } from '../../../../../services/lesson/lesson.types';
import { usePlayingGroupNote } from './use-playing-group-note';

type StepTeacherPlayingGroupNoteProps = {
    step: DerivedTeacherPlayingStep;
};

function getBadgeColorVariant(type: 'chord' | 'scale'): ColorVariant {
    switch (type) {
        case 'chord':
            return 'yellow';
        case 'scale':
            return 'emerald';
    }
}

export function StepTeacherPlayingGroupNote({
    step,
}: StepTeacherPlayingGroupNoteProps) {
    const playingGroupNote = usePlayingGroupNote(
        PartId.LESSON_TEACHER_PLAYING,
        step.eventsInPart,
    );
    const { n } = useNoteNotation();
    const { t } = useTranslation();

    return (
        <AnimatePresence mode="popLayout">
            {playingGroupNote !== null && playingGroupNote.type !== 'none' ? (
                <BadgeMotion
                    initial={{ y: 5, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -5, opacity: 0 }}
                    colorVariant={getBadgeColorVariant(playingGroupNote.type)}
                    key={playingGroupNote.type}
                >
                    {playingGroupNote !== null &&
                    playingGroupNote.type === 'chord'
                        ? playingGroupNote.valueToDisplay
                        : null}
                    {playingGroupNote !== null &&
                    playingGroupNote.type === 'scale'
                        ? `${n(playingGroupNote.valueToDisplay.tonicPitchClass)} ${t(`scale.name.${playingGroupNote.valueToDisplay.type}` as TranslationKey)}`
                        : null}
                </BadgeMotion>
            ) : null}
        </AnimatePresence>
    );
}

export enum Consumers {
    CONFIGURATION_STATE_TRY = 'configuration-state:try',
    MIC_TUTORIAL_EXPLAIN_PERMISSION_STEP = 'mic-tutorial/explain-permission-step',
    MIC_TUTORIAL_GAIN_SETTING_STEP = 'mic-tutorial/gain-setting-step',
    MIC_TUTORIAL_TUNING_STEP = 'mic-tutorial/tuning-step',
    QUIZ_PLAYING = 'quiz-playing',
    SETTINGS_MIC_SECTION_TRY = 'mic-section:try',
    SETTINGS_MIC_SETTING_CONTROLLER_TRY = 'mic-setting-controller:try',
    SOUND_DEBUG_CAPTURE = 'sound-capture-debug',
    TESTING = 'testing',
}

import { useEffect, useState } from 'react';
import { soundPlayer } from '../../../../../services/sound-player/boot';
import { PartId } from '../../../../../services/sequence/sequence.constants';
import { PlayButton } from '../../../../ui/play-button';
import { DerivedTeacherPlayingStep } from '../../../../../services/lesson/lesson.types';

type StepTeacherPlayingPlayButtonProps = {
    step: DerivedTeacherPlayingStep;
};

export function StepTeacherPlayingPlayButton({
    step,
}: StepTeacherPlayingPlayButtonProps) {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
        if (!isPlaying) {
            soundPlayer.playEventsInPart(
                PartId.LESSON_TEACHER_PLAYING,
                step.eventsInPart,
            );
        } else {
            soundPlayer.stop();
        }
    };

    const handlePartStart = ({ partId }: { partId: PartId }) => {
        if (partId === PartId.LESSON_TEACHER_PLAYING) {
            setIsPlaying(true);
        }
    };

    const handleStop = () => {
        setIsPlaying(false);
    };

    useEffect(() => {
        soundPlayer.on('part-start', handlePartStart);
        soundPlayer.on('stop', handleStop);

        return () => {
            soundPlayer.off('part-start', handlePartStart);
            soundPlayer.off('stop', handleStop);
        };
    }, []);

    return <PlayButton isPlaying={isPlaying} onClick={handlePlayClick} />;
}

import {
    FretboardNoteDetails,
    isPositionEqual,
} from '../../../../../services/fretboard';
import { BuildCellsComponentProps } from '../../../guitar-neck/guitar-neck.types';
import { NeckCellInterval } from '../../../neck-cells/neck-cell-interval';
import { NeckNote } from '../../../neck-cells/neck-note';
import { PlayingNotesState } from './playing-notes.reducer';
import { NotesToShow } from './notes-to-show.reducer';

type StepTeacherPlayingNeckCellProps = BuildCellsComponentProps & {
    fretboardNoteDetails: FretboardNoteDetails;
    notesToDisplay: NotesToShow[];
    playingNotes: PlayingNotesState;
};

export function StepTeacherPlayingNeckCell({
    fretboardNoteDetails,
    isOpenString,
    isVertical,
    notesToDisplay,
    playingNotes,
    position,
    getElementByPosition,
}: StepTeacherPlayingNeckCellProps) {
    const noteToDisplay = notesToDisplay.find((noteToDisplay) =>
        isPositionEqual(noteToDisplay.position, position),
    );

    const isPlayed = playingNotes.some((playingNote) =>
        isPositionEqual(playingNote.position, position),
    );

    const fretboardNote = getElementByPosition(fretboardNoteDetails);

    if (noteToDisplay === undefined && position[1] === 0 && !isPlayed) {
        return (
            <NeckNote
                isOpenString={isOpenString}
                isVertical={isVertical}
                position={position}
                notePitchClass={fretboardNote.pitchClass}
            />
        );
    }

    return (
        <>
            {noteToDisplay !== undefined &&
            noteToDisplay.noteDisplayMode === 'pitch-class' ? (
                <NeckNote
                    isPlayed={isPlayed}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    notePitchClass={noteToDisplay.pitchClassToDisplay}
                    position={position}
                />
            ) : null}
            {noteToDisplay !== undefined &&
            noteToDisplay.noteDisplayMode === 'interval' &&
            noteToDisplay.intervalToDisplay !== undefined ? (
                <NeckCellInterval
                    isPlayed={isPlayed}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    interval={noteToDisplay.intervalToDisplay}
                    position={position}
                />
            ) : null}
            {noteToDisplay === undefined && isPlayed ? (
                <NeckNote
                    isPlayed
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    position={position}
                    notePitchClass={fretboardNote.pitchClass}
                />
            ) : null}
        </>
    );
}

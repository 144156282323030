import { useCallback, useEffect } from 'react';
import { Thematic } from '@notacami/core/thematic';
import { useTryToResumeOrPrompt } from '../../hooks/use-try-to-resume-or-prompt';
import { MicError } from '../../services/pitch-detection/types';
import { Consumers } from '../../services/pitch-detection-consumer/consumers';
import { useMicSettingStore } from './mic-setting.store';
import { useMicErrorDialogStore } from './mic-error-dialog.store';
import { MicSetting } from './mic-setting';

type MicSettingModalControllerProps = {
    open: boolean;
    onClose: () => void;
    thematic: Thematic;
};

export function MicSettingModalController({
    open,
    onClose,
    thematic,
}: Readonly<MicSettingModalControllerProps>) {
    const openMicErrorDialog = useMicErrorDialogStore(
        (state) => state.openDialog,
    );
    const askForPermission = useMicSettingStore(
        (state) => state.askForPermission,
    );
    const modalOpened = useMicSettingStore((state) => state.modalOpened);
    const openComplete = useMicSettingStore((state) => state.openComplete);
    const reset = useMicSettingStore((state) => state.reset);

    const handleClose = useCallback(() => {
        onClose();
        reset();
    }, [onClose, reset]);

    const handleResumeSucceed = useCallback(() => {
        openComplete(false);
    }, [openComplete]);

    const handleResumeFail = useCallback(
        (micError: MicError) => {
            handleClose();
            openMicErrorDialog(micError);
        },
        [handleClose, openMicErrorDialog],
    );

    const handleNeedToPrompt = useCallback(() => {
        openComplete(true);
    }, [openComplete]);

    const { execute, isPending } = useTryToResumeOrPrompt({
        consumerId: Consumers.SETTINGS_MIC_SETTING_CONTROLLER_TRY,
        needToPrompt: handleNeedToPrompt,
        resumeFail: handleResumeFail,
        resumeSucceed: handleResumeSucceed,
    });

    useEffect(() => {
        if (open && !modalOpened && !isPending) {
            execute();
        }
        if (!open && modalOpened && !isPending) {
            reset();
        }
    }, [open, execute, isPending, reset, modalOpened]);

    return (
        <MicSetting
            onClose={handleClose}
            open={modalOpened}
            askForPermission={askForPermission}
            thematic={thematic}
        />
    );
}

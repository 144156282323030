import { useEffect, useState } from 'react';
import { NoteEvent } from '@notacami/core/composer';
import { soundPlayer } from '../../../services/sound-player/boot';
import { PartId } from '../../../services/sequence/sequence.constants';
import { PositionWithInterval } from '../../../services/fretboard';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { PlayButton } from '../../ui/play-button';

type VisualizeScalePositionsPlayNotesProps = {
    positionsWithInterval: PositionWithInterval[];
};

export function VisualizeScalePositionsPlayNotes({
    positionsWithInterval,
}: VisualizeScalePositionsPlayNotesProps) {
    const [isPlaying, setIsPlaying] = useState(false);

    const fretboard = usePreferencesStore((state) => state.fretboard);

    const handlePlayClick = () => {
        if (!isPlaying) {
            const noteEvents: NoteEvent[] = [
                ...positionsWithInterval,
                ...positionsWithInterval
                    .slice(0, positionsWithInterval.length - 1)
                    .reverse(),
            ].map(({ position }, index) => ({
                type: 'note',
                position: position,
                duration: 0.25,
                time: 0.25 * index,
            }));
            soundPlayer.playSequence(
                PartId.VISUALIZE_SCALE_POSITIONS,
                noteEvents,
                fretboard.noteDetails,
            );
        } else {
            soundPlayer.stop();
        }
    };

    const handlePartStart = ({ partId }: { partId: PartId }) => {
        if (partId === PartId.VISUALIZE_SCALE_POSITIONS) {
            setIsPlaying(true);
        }
    };

    const handleStop = () => {
        setIsPlaying(false);
    };

    useEffect(() => {
        soundPlayer.on('part-start', handlePartStart);
        soundPlayer.on('stop', handleStop);

        return () => {
            soundPlayer.off('part-start', handlePartStart);
            soundPlayer.off('stop', handleStop);
        };
    }, []);

    return <PlayButton isPlaying={isPlaying} onClick={handlePlayClick} />;
}

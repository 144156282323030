import Emittery from 'emittery';
import { NoteName } from '../notes/notes.types';
import { DEFAULT_REFERENCE_FREQUENCY } from '../frequency/frequency.constants';
import { getNoteDetails } from '../notes/get-note-details';
import { IPitchDetectionService } from '../pitch-detection/types';
import { getNoteFromPitch } from './utils';
import {
    INotePlayedService,
    NoteFromPitch,
    NotePlayedServiceEvents,
} from './types';

export class NotePlayedService
    extends Emittery<NotePlayedServiceEvents>
    implements INotePlayedService
{
    private bufferNote: NoteFromPitch[] = [];
    private BUFFER_MAX_LENGTH = 30;
    private SIGNIFICANT_NOTE_PRESENCE_IN_BUFFER = 20;
    private previousNoteName: NoteName | undefined;
    private previousOctave: number | undefined;
    private previousCentsOff: number | undefined;
    private referenceFrequency: number = DEFAULT_REFERENCE_FREQUENCY;

    constructor(
        private readonly pitchDetectionService: IPitchDetectionService,
    ) {
        super();

        this.pitchDetectionService.on(
            'frequency-detect',
            (frequency: number) => {
                this.addToBuffer(frequency);
            },
        );
        this.pitchDetectionService.on('silence-detect', () => {
            this.cleanBuffer();
        });
    }

    public setReferenceFrequency(value: number) {
        this.referenceFrequency = value;
    }

    private groupNoteNameToCount(
        accumulator: Record<string, number>,
        current: NoteFromPitch,
    ): Record<string, number> {
        const currentNoteName = current.name;
        if (!accumulator[currentNoteName]) {
            accumulator[currentNoteName] = 1;
        } else {
            accumulator[currentNoteName] += 1;
        }
        return accumulator;
    }

    private getNoteInfoInBuffer(
        bufferNote: NoteFromPitch[],
        noteName: NoteName,
    ) {
        const bufferFiltered = bufferNote.filter(
            ({ name }) => name === noteName,
        );

        const bufferFilteredLenght = bufferFiltered.length;

        const lastCentsOff = bufferFiltered[bufferFilteredLenght - 1].centsOff;

        const octaves = bufferFiltered.map(({ octave }) => octave);

        // maybe take the middle frequency instead of lower frequency
        // or maybe give an option to user to choose between lower / middle / more present
        const lowerOctave = Math.min(...octaves);
        return {
            centsOff: lastCentsOff,
            octave: lowerOctave,
        };
    }

    private isCurrentOctaveIsLowerThanPrevious(
        previousNoteName: NoteName | undefined,
        currentNoteName: NoteName,
        previousOctave: number | undefined,
        currentOctave: number,
    ) {
        return (
            previousNoteName === currentNoteName &&
            previousOctave !== undefined &&
            currentOctave < previousOctave
        );
    }

    private addToBuffer(frequency: number) {
        const noteFromPitch = getNoteFromPitch(
            frequency,
            this.referenceFrequency,
        );

        this.bufferNote.push(noteFromPitch);

        if (this.bufferNote.length > this.BUFFER_MAX_LENGTH) {
            this.bufferNote.shift();
        }

        const numOfElementsByNoteName = this.bufferNote.reduce<
            Record<string, number>
        >(this.groupNoteNameToCount, {});

        const keys = Object.keys(numOfElementsByNoteName);
        const values = Object.values(numOfElementsByNoteName);

        const maxElement = Math.max(...values);

        const isNoteSignificantlyDetected =
            maxElement >= this.SIGNIFICANT_NOTE_PRESENCE_IN_BUFFER;

        if (!isNoteSignificantlyDetected) return;

        const index = values.indexOf(maxElement);

        const currentNoteNameMorePresent = keys[index];

        const { octave, centsOff } = this.getNoteInfoInBuffer(
            this.bufferNote,
            currentNoteNameMorePresent,
        );

        const isCurrentOctaveIsLowerThanPrevious =
            this.isCurrentOctaveIsLowerThanPrevious(
                this.previousNoteName,
                currentNoteNameMorePresent,
                this.previousOctave,
                octave,
            );

        const isCurrentNoteDifferentFromPreviousOne =
            this.previousNoteName !== currentNoteNameMorePresent;

        if (isCurrentOctaveIsLowerThanPrevious) {
            this.previousOctave = octave;
        }

        if (
            this.previousNoteName !== currentNoteNameMorePresent ||
            this.previousCentsOff !== centsOff
        ) {
            this.previousNoteName = currentNoteNameMorePresent;
            this.previousOctave = octave;
            this.previousCentsOff = centsOff;
        }

        window.requestAnimationFrame(() => {
            if (
                isCurrentNoteDifferentFromPreviousOne &&
                this.previousNoteName !== undefined &&
                this.previousOctave !== undefined
            ) {
                this.emitChangedEvent(
                    this.previousNoteName,
                    this.previousOctave,
                );
            }
            if (
                this.previousOctave === undefined ||
                this.previousNoteName === undefined ||
                this.previousCentsOff === undefined
            ) {
                return;
            }

            this.emitDetailedNoteDetectedEvent(
                this.previousNoteName,
                this.previousOctave,
                this.previousCentsOff,
            );
        });
    }

    private emitChangedEvent(noteName: NoteName, octave: number) {
        const noteDetails = getNoteDetails(`${noteName}${octave}`);
        this.emit('changed', { noteDetails });
    }

    private emitDetailedNoteDetectedEvent(
        name: NoteName,
        octave: number,
        centsOff: number,
    ) {
        this.emit('detailed-note-detect', {
            note: {
                name,
                octave,
                centsOff,
            },
        });
    }

    public cleanBuffer() {
        this.bufferNote = [];
        this.previousNoteName = undefined;
        this.previousOctave = undefined;
        this.previousCentsOff = undefined;
    }
}

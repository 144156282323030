import { useCallback, useEffect, useReducer } from 'react';
import { soundPlayer } from '../../../../../services/sound-player/boot';
import {
    EventInPart,
    GroupNoteEndEvent,
    GroupNoteStartEvent,
} from '../../../../../services/composer';
import { PartId } from '../../../../../services/sequence/sequence.constants';
import { playingGroupNoteReducer } from './playing-group-note.reducer';

function getGroupNoteInfoToDisplay(groupNoteStartEvent: GroupNoteStartEvent) {
    switch (groupNoteStartEvent.groupType) {
        case 'chord':
            return {
                type: groupNoteStartEvent.groupType,
                id: groupNoteStartEvent.id,
                valueToDisplay: groupNoteStartEvent.valueToDisplay,
                value: groupNoteStartEvent.value,
            };
        case 'scale':
            return {
                type: groupNoteStartEvent.groupType,
                id: groupNoteStartEvent.id,
                valueToDisplay: groupNoteStartEvent.valueToDisplay,
                value: groupNoteStartEvent.value,
            };
        case 'none':
            return {
                type: groupNoteStartEvent.groupType,
                id: groupNoteStartEvent.id,
                valueToDisplay: null,
                value: null,
            };
    }
}

export function usePlayingGroupNote(
    partIdToUse: PartId,
    eventsInPart: EventInPart[],
) {
    const firstGroupNoteStartEvent = eventsInPart
        .filter((event) => event.type === 'group-note-start')
        .find((event) => event.time === 0);

    const [state, dispatch] = useReducer(playingGroupNoteReducer, {
        firstPlayingGroup: firstGroupNoteStartEvent
            ? getGroupNoteInfoToDisplay(firstGroupNoteStartEvent)
            : null,
        playingGroupNote: [],
    });

    const handleGroupNoteStart = useCallback(
        ({
            partId,
            groupNoteStartEvent,
        }: {
            partId: PartId;
            groupNoteStartEvent: GroupNoteStartEvent;
        }) => {
            if (partId === partIdToUse) {
                dispatch({
                    type: 'GROUP_NOTE_START',
                    groupNote: getGroupNoteInfoToDisplay(groupNoteStartEvent),
                });
            }
        },
        [partIdToUse],
    );

    const handleGroupNoteEnd = useCallback(
        ({
            partId,
            groupNoteEndEvent,
        }: {
            partId: PartId;
            groupNoteEndEvent: GroupNoteEndEvent;
        }) => {
            if (partId === partIdToUse) {
                dispatch({
                    type: 'GROUP_NOTE_END',
                    id: groupNoteEndEvent.id,
                });
            }
        },
        [partIdToUse],
    );

    const handleStop = useCallback(() => {
        dispatch({
            type: 'RESET',
        });
    }, []);

    useEffect(() => {
        soundPlayer.on('group-note-start', handleGroupNoteStart);
        soundPlayer.on('group-note-end', handleGroupNoteEnd);
        soundPlayer.on('stop', handleStop);

        return () => {
            soundPlayer.off('group-note-start', handleGroupNoteStart);
            soundPlayer.off('group-note-end', handleGroupNoteEnd);
            soundPlayer.off('stop', handleStop);
        };
    }, [handleGroupNoteStart, handleGroupNoteEnd, handleStop]);

    return state.playingGroupNote.length > 0 ? state.playingGroupNote[0] : null;
}

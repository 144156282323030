import { LessonInfo } from '@notacami/core/lesson';
import { LESSON_BASE, SEQUENCE_SAMPLE } from './demo-lesson-base';

export const DEMO_LESSON_EN_FR: LessonInfo = {
    ...LESSON_BASE,
    id: 'demo-lesson-en-fr',
    steps: [
        {
            id: '0',
            type: 'teacher-speaking',
            text: [
                {
                    locale: 'en',
                    text: 'Text En 0\n\n**Salut** <LogoText /> super logo _italic_',
                },
                {
                    locale: 'fr',
                    text: 'Text Fr 0\n\n**Salut** <LogoText /> super logo _italic_',
                },
            ],
        },
        {
            id: '1',
            type: 'teacher-speaking',
            text: [
                { locale: 'en', text: 'Text En 1' },
                { locale: 'fr', text: 'Text Fr 1' },
            ],
        },
        {
            id: '2',
            type: 'teacher-playing',
            sequence: SEQUENCE_SAMPLE,
        },
        {
            id: '3',
            type: 'teacher-speaking',
            text: [
                { locale: 'en', text: 'Text En 2' },
                { locale: 'fr', text: 'Text Fr 2' },
            ],
        },
    ],
};

import { useCallback, useContext, useEffect, useRef } from 'react';
import { getCanvas } from '../sound-capture-debug/FrequenciesGraph/utils/get-canvas';
import { eraseAll } from '../sound-capture-debug/FrequenciesGraph/utils/erase-all';
import { drawSinewave } from '../sound-capture-debug/FrequenciesGraph/utils/draw-sinewave';
import { UpdatedData } from '../../../services/pitch-detection/types';
import { ThemeContext } from '../theme.context';
import { ServicesContext } from '../../../services/services.context';

export function SinewavePreview() {
    const { pitchDetection } = useContext(ServicesContext);
    const { theme } = useContext(ThemeContext);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const clearCanvas = useCallback(() => {
        const canvas = getCanvas(canvasRef.current);

        if (canvas) {
            eraseAll(canvas);
        }
    }, [canvasRef]);

    const handleSinewaveUpdate = useCallback(
        ({ buffer, bufferLength }: UpdatedData) => {
            const canvas = getCanvas(canvasRef.current);

            if (canvas) {
                drawSinewave(buffer, canvas, bufferLength, theme);
            }
        },
        [canvasRef, theme],
    );

    const handleMicClose = useCallback(() => {
        clearCanvas();
    }, [clearCanvas]);

    useEffect(() => {
        pitchDetection.on('sinewave-update', handleSinewaveUpdate);
        pitchDetection.on('mic-close', handleMicClose);

        return () => {
            pitchDetection.off('sinewave-update', handleSinewaveUpdate);
            pitchDetection.off('mic-close', handleMicClose);
        };
    }, [handleSinewaveUpdate, handleMicClose]);

    return (
        <canvas
            className="absolute top-0 left-0 bottom-0 right-0"
            ref={canvasRef}
        />
    );
}

import { useCallback, useEffect, useReducer } from 'react';
import { soundPlayer } from '../../../../../services/sound-player/boot';
import { NoteEndEvent, NoteStartEvent } from '../../../../../services/composer';
import { PartId } from '../../../../../services/sequence/sequence.constants';
import { playingNotesReducer } from './playing-notes.reducer';

export function usePlayingNotes(partIdToUse: PartId) {
    const [playingNotes, dispatch] = useReducer(playingNotesReducer, []);

    const handleNoteStart = useCallback(
        ({
            partId,
            noteStartEvent,
        }: {
            partId: PartId;
            noteStartEvent: NoteStartEvent;
        }) => {
            if (partId === partIdToUse) {
                dispatch({
                    type: 'NOTE_START',
                    id: noteStartEvent.id,
                    position: noteStartEvent.position,
                });
            }
        },
        [partIdToUse],
    );

    const handleNoteEnd = useCallback(
        ({
            partId,
            noteEndEvent,
        }: {
            partId: PartId;
            noteEndEvent: NoteEndEvent;
        }) => {
            if (partId === partIdToUse) {
                dispatch({
                    type: 'NOTE_END',
                    id: noteEndEvent.id,
                });
            }
        },
        [partIdToUse],
    );

    const handleStop = useCallback(() => {
        dispatch({
            type: 'RESET',
        });
    }, []);

    useEffect(() => {
        soundPlayer.on('note-start', handleNoteStart);
        soundPlayer.on('note-end', handleNoteEnd);
        soundPlayer.on('stop', handleStop);

        return () => {
            soundPlayer.off('note-start', handleNoteStart);
            soundPlayer.off('note-end', handleNoteEnd);
            soundPlayer.off('stop', handleStop);
        };
    }, [handleNoteStart, handleNoteEnd, handleStop]);

    return playingNotes;
}

import { useContext } from 'react';
import { useStore } from 'zustand';
import { LessonContext } from '../../../services/lesson';
import { LessonIntroduction } from './main-steps/introduction/lesson-introduction';
import { LessonSteps } from './main-steps/steps/lesson-steps';
import { EndConfirmationState } from './main-steps/end-confimation/end-confirmation-state';
import { EndSummaryState } from './main-steps/end-summary/end-summary-state';

export function LessonMainSteps() {
    const store = useContext(LessonContext);
    const mainStep = useStore(store, (state) => state.mainStep);
    const derivedLessonInfo = useStore(
        store,
        (state) => state.derivedLessonInfo,
    );
    const lessonLanguage = useStore(store, (state) => state.lessonLanguage);
    const goToSteps = useStore(store, (state) => state.goToSteps);

    const handleStartClick = () => {
        goToSteps();
    };

    return derivedLessonInfo && lessonLanguage ? (
        <>
            {mainStep === 'introduction' ? (
                <LessonIntroduction
                    mainThematic={derivedLessonInfo.mainThematic}
                    name={derivedLessonInfo.name}
                    currentLanguage={lessonLanguage}
                    estimatedDuration={derivedLessonInfo.estimatedDuration}
                    description={derivedLessonInfo.description}
                    lessonType={derivedLessonInfo.lessonType}
                    disableStartButton={derivedLessonInfo.steps.length === 0}
                    onStartClick={handleStartClick}
                />
            ) : null}
            {mainStep === 'steps' || mainStep === 'end-confirmation' ? (
                <LessonSteps />
            ) : null}
            {mainStep === 'end-summary' ? <EndSummaryState /> : null}
            <EndConfirmationState />
        </>
    ) : null;
}

import { DEFAULT_FRET_LENGTH } from '../../../../../apps/app/src/services/fretboard/fretboard.constants';
import { Sequence } from '../../composer';
import { LessonInfo } from '../lesson.types';

export const LESSON_BASE: Omit<LessonInfo, 'id' | 'steps'> = {
    availableLanguages: ['en', 'fr'],
    tuningId: 'guitar-standard',
    fretLength: DEFAULT_FRET_LENGTH,
    name: [
        { locale: 'en', text: 'My great course on intervals' },
        { locale: 'fr', text: 'Mon super cours sur les intervales' },
    ],
    description: [
        {
            locale: 'en',
            text: 'Lorem ipsum dolor sit amet en, consectetur adipiscing elit. Sed urna sapien, gravida ut justo eget, hendrerit interdum diam. Vestibulum semper non tellus a commodo. Phasellus vel bibendum felis. Suspendisse vitae pulvinar est. Proin non rhoncus dolor, in convallis mauris. Pellentesque quis rutrum odio. Nunc nec enim vel purus consequat congue eu quis felis.',
        },
        {
            locale: 'fr',
            text: 'Lorem ipsum dolor sit amet fr, consectetur adipiscing elit. Sed urna sapien, gravida ut justo eget, hendrerit interdum diam. Vestibulum semper non tellus a commodo. Phasellus vel bibendum felis. Suspendisse vitae pulvinar est. Proin non rhoncus dolor, in convallis mauris. Pellentesque quis rutrum odio. Nunc nec enim vel purus consequat congue eu quis felis.',
        },
    ],
    mainThematic: 'intervals',
    estimatedDuration: 14,
    lessonType: 'fretboard-exercise',
};

export const SEQUENCE_SAMPLE: Sequence = [
    {
        type: 'group-note',
        groupType: 'scale',
        value: 'G harmonic minor',
        noteDisplayMode: 'interval',
        time: 0,
        duration: 1,
    },
    { type: 'note', position: [0, 3], time: 0, duration: 0.25 },
    { type: 'note', position: [1, 1], time: 0.25, duration: 0.25 },
    { type: 'note', position: [2, 0], time: 0.25, duration: 1 },
    { type: 'note', position: [2, 4], time: 0.5, duration: 0.25 },
    { type: 'note', position: [2, 5], time: 0.75, duration: 0.25 },
    {
        type: 'group-note',
        groupType: 'chord',
        value: { type: 'maj7', tonic: 'C', bass: 'B' },
        noteDisplayMode: 'pitch-class',
        time: 1,
        duration: 1,
    },
    { type: 'note', position: [0, 7], time: 1, duration: 0.25 },
    { type: 'note', position: [0, 8], time: 1.25, duration: 0.25 },
    { type: 'note', position: [1, 7], time: 1.5, duration: 0.25 },
    { type: 'note', position: [2, 9], time: 1.75, duration: 0.25 },
    {
        type: 'group-note',
        groupType: 'scale',
        value: 'G harmonic minor',
        noteDisplayMode: 'pitch-class',
        time: 2,
        duration: 1,
    },
    { type: 'note', position: [0, 3], time: 2, duration: 0.25 },
    { type: 'note', position: [1, 1], time: 2.25, duration: 0.25 },
    { type: 'note', position: [2, 4], time: 2.5, duration: 0.25 },
    { type: 'note', position: [2, 5], time: 2.75, duration: 0.25 },
    { type: 'group-note', groupType: 'none', duration: 1, time: 3 },
    { type: 'note', position: [0, 3], time: 3, duration: 0.25 },
    { type: 'note', position: [1, 1], time: 3.25, duration: 0.25 },
    { type: 'note', position: [2, 4], time: 3.5, duration: 0.25 },
    { type: 'note', position: [2, 5], time: 3.75, duration: 0.25 },
    {
        type: 'group-note',
        groupType: 'chord',
        value: { type: 'maj7', tonic: 'C', bass: 'B' },
        noteDisplayMode: 'interval',
        time: 4,
        duration: 1,
    },
    { type: 'note', position: [0, 7], time: 4, duration: 0.25 },
    { type: 'note', position: [0, 8], time: 4.25, duration: 0.25 },
    { type: 'note', position: [1, 7], time: 4.5, duration: 0.25 },
    { type: 'note', position: [2, 9], time: 4.75, duration: 0.25 },
    { type: 'group-note', groupType: 'none', duration: 1, time: 5 },
    { type: 'note', position: [0, 3], time: 5, duration: 0.25 },
    { type: 'note', position: [1, 1], time: 5.25, duration: 0.25 },
    { type: 'note', position: [2, 4], time: 5.5, duration: 0.25 },
    { type: 'note', position: [2, 5], time: 5.75, duration: 0.25 },
    {
        type: 'group-note',
        groupType: 'scale',
        value: 'G harmonic minor',
        noteDisplayMode: 'pitch-class',
        time: 6,
        duration: 1,
    },
    { type: 'note', position: [0, 3], time: 6, duration: 0.25 },
    { type: 'note', position: [1, 1], time: 6.25, duration: 0.25 },
    { type: 'note', position: [2, 4], time: 6.5, duration: 0.25 },
    { type: 'note', position: [2, 5], time: 6.75, duration: 0.25 },
];

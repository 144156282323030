import { SyntheticEvent } from 'react';
import { useTranslation } from '../../hooks/use-translation';
import { TranslationKey } from '../../services/i18n/translations.types';
import { getScaleList } from '../../services/scales/scale-list';

type ScaleSelectorProps = {
    defaultValue: string;
    onChange: (id: string) => void;
};

export function ScaleSelectorTonal({
    defaultValue,
    onChange,
}: ScaleSelectorProps) {
    const { t } = useTranslation();

    const handleChange = (event: SyntheticEvent<HTMLSelectElement>) => {
        const id = event.currentTarget.value;
        onChange(id);
    };

    return (
        <form className="flex gap-4 justify-center items-center">
            <label htmlFor="scale-selector" className="block text-sm">
                {t('fields.scale.label')}
            </label>
            <select
                defaultValue={defaultValue}
                onChange={handleChange}
                name="scale-selector"
                id="scale-selector"
                className="select max-w-44"
            >
                {getScaleList().map((name) => (
                    <option key={name} value={name}>
                        {t(`scale.name.${name}` as TranslationKey)}
                    </option>
                ))}
            </select>
        </form>
    );
}

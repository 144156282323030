import { useContext, useEffect } from 'react';
import { useStore } from 'zustand';
import { Button } from '@notacami/ui';
import { getColorVariantByThematic } from '@notacami/core/design';
import { LessonContext } from '../../../../../services/lesson';
import { useTranslation } from '../../../../../hooks/use-translation';
import { LessonLayout } from '../../../lesson-layout';
import { PartId } from '../../../../../services/sequence/sequence.constants';
import { soundPlayer } from '../../../../../services/sound-player/boot';
import { LeftActionSection } from './left-action-section';
import { StepTeacherSpeakingWrapper } from './step-teacher-speaking-wrapper';
import { StepTeacherPlayingWrapper } from './step-teacher-playing-wrapper';
import { StepTeacherPlayingPlayButton } from './step-teacher-playing-play-button';
import { StepTeacherPlayingGroupNote } from './step-teacher-playing-group-note';

export function LessonSteps() {
    const { t } = useTranslation();
    const store = useContext(LessonContext);
    const currentStepId = useStore(store, (state) => state.currentStepId);
    const derivedLessonInfo = useStore(
        store,
        (state) => state.derivedLessonInfo,
    );
    const updateCurrentStepId = useStore(
        store,
        (state) => state.updateCurrentStepId,
    );
    const nextStepId = useStore(store, (state) => state.nextStepId);
    const goToEndSummary = useStore(store, (state) => state.goToEndSummary);
    const fretboard = useStore(store, (state) => state.fretboardNoteDetails);

    const currentStep =
        derivedLessonInfo !== null && currentStepId !== null
            ? derivedLessonInfo.steps.find(({ id }) => id === currentStepId)
            : undefined;

    const nextStep =
        derivedLessonInfo !== null && nextStepId !== null
            ? derivedLessonInfo.steps.find(({ id }) => id === nextStepId)
            : undefined;

    const mainThematic = derivedLessonInfo?.mainThematic;

    const handleNextClick = () => {
        if (nextStep !== undefined) {
            updateCurrentStepId(nextStepId);
            if (nextStep.type === 'teacher-playing' && fretboard !== null) {
                soundPlayer.playEventsInPart(
                    PartId.LESSON_TEACHER_PLAYING,
                    nextStep.eventsInPart,
                );
            }
        } else {
            goToEndSummary();
        }
    };

    useEffect(() => {
        if (
            currentStep !== undefined &&
            currentStep.type !== 'teacher-playing'
        ) {
            soundPlayer.stop();
        }
    }, [currentStep]);

    return (
        <LessonLayout
            resizeWhenDrawerOpened
            topLeftAction={<LeftActionSection />}
            center={
                <div className="flex flex-col justify-center h-full p-4">
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-speaking' ? (
                        <StepTeacherSpeakingWrapper step={currentStep} />
                    ) : null}
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-playing' ? (
                        <StepTeacherPlayingWrapper step={currentStep} />
                    ) : null}
                </div>
            }
            topCenter={
                <>
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-playing' ? (
                        <StepTeacherPlayingGroupNote step={currentStep} />
                    ) : null}
                </>
            }
            bottomCenterAction={
                <>
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-playing' ? (
                        <StepTeacherPlayingPlayButton step={currentStep} />
                    ) : null}
                </>
            }
            bottomRightAction={
                <>
                    {mainThematic ? (
                        <Button
                            colorVariant={getColorVariantByThematic(
                                mainThematic,
                            )}
                            onClick={handleNextClick}
                        >
                            {t('button.next')}
                        </Button>
                    ) : null}
                </>
            }
        />
    );
}

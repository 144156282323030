import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { FindTheIntervalOnTheNeckExerciseContext } from '../../store/context';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import { QuestionSectionGeneric } from '../../../exercise/states/playing/question-section-generic';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';
import { useIntervalTranslation } from '../../../../../services/i18n/use-interval-translation';

export function QuestionSection() {
    const tuning = usePreferencesStore((state) => state.tuningInfo);
    const { n } = useNoteNotation();
    const { i } = useIntervalTranslation();
    const numberOfStrings = tuning.notes.length;

    const store = useContext(FindTheIntervalOnTheNeckExerciseContext);
    const question = useQuestion(store);

    if (!question) {
        throw new Error('question not found');
    }

    const givenRootNoteName = question.question.givenRootNoteName;
    const givenRootStringIndex = question.question.givenRootPosition[0];
    const givenInterval = question.question.givenInterval;

    const key = `${givenRootNoteName}${givenRootStringIndex}${givenInterval}`;

    return (
        <QuestionSectionGeneric key={key} store={store}>
            <Trans
                i18nKey="pages./find-the-interval-on-the-neck.question"
                values={{
                    note: n(givenRootNoteName),
                    stringIndex: numberOfStrings - givenRootStringIndex,
                    interval: i(givenInterval),
                }}
                components={[<strong key={0} />]}
            />
        </QuestionSectionGeneric>
    );
}

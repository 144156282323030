import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { cn } from '@notacami/core/css';
import { DetailedNoteDetect } from '../../../../services/note-played/types';
import { useNoteNotation } from '../../../../services/note-notation/use-note-notation';
import { ServicesContext } from '../../../../services/services.context';
import { TunerHeadCentsOffIndicator } from './tuner-head/tuner-head-cents-off-indicator';
import { getIndicatorVariant } from './tuner-head/utils';

export function TunerHead() {
    const { pitchDetection, notePlayed } = useContext(ServicesContext);
    const { n, noteNotation } = useNoteNotation();
    const resetTimeoutIdRef = useRef<number>();
    const [currentNotePlayed, setCurrentNotePlayed] =
        useState<DetailedNoteDetect>();

    const handleDetailedNoteDetect = useCallback(
        ({ note }: { note: DetailedNoteDetect }) => {
            setCurrentNotePlayed(note);

            if (resetTimeoutIdRef.current) {
                clearTimeout(resetTimeoutIdRef.current);
            }
            resetTimeoutIdRef.current = window.setTimeout(resetData, 2000);
        },
        [],
    );

    const handleMicClose = useCallback(() => {
        resetData();
    }, []);

    const resetData = () => {
        setCurrentNotePlayed(undefined);
    };

    useEffect(() => {
        notePlayed.on('detailed-note-detect', handleDetailedNoteDetect);
        pitchDetection.on('mic-close', handleMicClose);

        return () => {
            notePlayed.off('detailed-note-detect', handleDetailedNoteDetect);
            pitchDetection.off('mic-close', handleMicClose);
        };
    }, [handleMicClose, handleDetailedNoteDetect]);

    const flatIndicatorVariant = getIndicatorVariant(
        currentNotePlayed?.centsOff,
        true,
    );
    const sharpIndicatorVariant = getIndicatorVariant(
        currentNotePlayed?.centsOff,
        false,
    );

    return (
        <div className="z-20 flex gap-2 items-end">
            <TunerHeadCentsOffIndicator
                symbol="♭"
                variant={flatIndicatorVariant}
            />

            <div className="relative shadow-xl shadow-neutral-900/20 border-neutral-300 border-2 rounded-xl w-16 h-16 bg-neutral-100 flex items-center justify-center">
                <span
                    className={cn(
                        noteNotation === 'english' ? 'text-3xl' : 'text-2xl',
                        ' text-neutral-700',
                    )}
                >
                    {currentNotePlayed?.name ? n(currentNotePlayed.name) : '-'}
                </span>
                <span className="absolute text-xs bottom-0 block text-neutral-600">
                    {currentNotePlayed?.octave || '-'}
                </span>
            </div>

            <TunerHeadCentsOffIndicator
                symbol="♯"
                variant={sharpIndicatorVariant}
            />
        </div>
    );
}

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RangeInput } from '../../../ui/range-input';
import { UpdatedData } from '../../../../services/pitch-detection/types';
import { ServicesContext } from '../../../../services/services.context';
import { drawGraph } from './utils/draw-graph';
import { eraseAll } from './utils/erase-all';
import { getCanvas } from './utils/get-canvas';
import FrequencyVerticalMarkers from './FrequencyVerticalMarkers';
import FrequencyHorizontalMarkers from './FrequencyHorizontalMarkers';

export default function FrequenciesGraph() {
    const { pitchDetection } = useContext(ServicesContext);
    const [range, setRange] = useState<number[]>([0, 100]);
    const [sampleRate, setSampleRate] = useState<number>();
    const [frequencyDetected, setFrequencyDetected] = useState<number>();

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const getRange = useCallback(() => {
        return {
            start: range[0],
            end: range[1],
        };
    }, [range]);

    const clearCanvas = useCallback(() => {
        const canvas = getCanvas(canvasRef.current);

        if (canvas) {
            eraseAll(canvas);
        }
    }, [canvasRef]);

    const handleFrequenciesUpdate = useCallback(
        ({ buffer, sampleRate, bufferLength: bufferLenght }: UpdatedData) => {
            const canvas = getCanvas(canvasRef.current);
            const range = getRange();
            setSampleRate(sampleRate);

            if (canvas) {
                drawGraph(buffer, range, canvas, bufferLenght);
            }
        },
        [canvasRef, getRange],
    );

    const handleMicClose = useCallback(() => {
        clearCanvas();
        setFrequencyDetected(undefined);
    }, [clearCanvas]);

    const handleValueChange = (values: number[]) => {
        setRange(values);
    };

    const handleFrequencyDetect = (frequency: number) => {
        setFrequencyDetected(frequency);
    };

    const handleSilenceDetect = () => {
        setFrequencyDetected(undefined);
    };

    useEffect(() => {
        pitchDetection.on('frequency-detect', handleFrequencyDetect);
        pitchDetection.on('silence-detect', handleSilenceDetect);
        pitchDetection.on('frequencies-update', handleFrequenciesUpdate);
        pitchDetection.on('mic-close', handleMicClose);

        return () => {
            pitchDetection.off('frequency-detect', handleFrequencyDetect);
            pitchDetection.off('silence-detect', handleSilenceDetect);
            pitchDetection.off('frequencies-update', handleFrequenciesUpdate);
            pitchDetection.off('mic-close', handleMicClose);
        };
    }, [handleFrequenciesUpdate, handleMicClose]);

    return (
        <div className="max-w-3xl">
            <div className="w-full bg-neutral-500 rounded-lg overflow-hidden">
                <div className="py-1 px-3 text-sm">
                    <span className="text-neutral-300">autocorrelation :</span>{' '}
                    <span className="text-neutral-50">
                        {frequencyDetected !== undefined
                            ? `${frequencyDetected} Hz`
                            : '-'}
                    </span>
                </div>
                <div className="relative bg-neutral-900 h-72 rounded-lg overflow-hidden">
                    <canvas className="absolute" ref={canvasRef} />
                    {sampleRate !== undefined ? (
                        <FrequencyVerticalMarkers
                            range={getRange()}
                            sampleRate={sampleRate}
                        />
                    ) : null}
                    <FrequencyHorizontalMarkers />
                </div>
            </div>
            <div className="flex w-full mt-4 justify-center">
                <RangeInput
                    min={0}
                    max={100}
                    onValueChange={handleValueChange}
                />
            </div>
        </div>
    );
}

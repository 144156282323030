import { IPitchDetectionService, MicError } from '../pitch-detection/types';
import { Consumers } from './consumers';
import { IPitchDetectionConsumerService } from './type';

export class PitchDetectionConsumerService
    implements IPitchDetectionConsumerService
{
    private consumerIds: string[] = [];
    private stopTimeoutId: number | undefined;
    private isResuming = false;
    private currentResumingPromise:
        | Promise<
              | {
                    error: MicError;
                }
              | {
                    error: null;
                }
          >
        | { error: null };

    constructor(
        private readonly pitchDetectionService: IPitchDetectionService,
    ) {}

    public async addConsumer(consumerId: Consumers) {
        const consumerIndex = this.consumerIds.findIndex(
            (id) => id === consumerId,
        );

        if (consumerIndex !== -1) {
            return;
        }

        if (this.stopTimeoutId !== undefined) {
            window.clearTimeout(this.stopTimeoutId);
        }

        this.consumerIds = [...this.consumerIds, consumerId];

        if (this.isResuming) {
            return this.currentResumingPromise;
        }

        this.isResuming = true;
        const error = await this._resume();
        this.isResuming = false;

        return error;
    }

    public removeConsumer(consumerId: Consumers) {
        const haveConsumerBeforeRemoving = this.consumerIds.length > 0;
        this.consumerIds = this.consumerIds.filter((id) => id !== consumerId);

        if (this.consumerIds.length === 0 && haveConsumerBeforeRemoving) {
            this.stopTimeoutId = window.setTimeout(() => {
                this._stop();
            }, 1000);
        }
    }

    private _resume() {
        this.currentResumingPromise = this.pitchDetectionService.resume();
        return this.currentResumingPromise;
    }

    private _stop() {
        this.pitchDetectionService.stop();
    }
}

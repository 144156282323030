import { useContext } from 'react';
import { useStore } from 'zustand';
import { LessonContext } from '../../../../../services/lesson';
import { DerivedTeacherSpeakingStep } from '../../../../../services/lesson/lesson.types';
import { StepTeacherSpeaking } from './step-teacher-speaking';

type StepTeacherSpeakingWrapperProps = {
    step: DerivedTeacherSpeakingStep;
};

export function StepTeacherSpeakingWrapper({
    step,
}: StepTeacherSpeakingWrapperProps) {
    const store = useContext(LessonContext);
    const lessonLanguage = useStore(store, (state) => state.lessonLanguage);

    const lessonInfo = useStore(store, (state) => state.derivedLessonInfo);

    const text = step.text.find(
        ({ locale }) => locale === lessonLanguage,
    )?.text;

    return lessonInfo !== null && text !== undefined ? (
        <StepTeacherSpeaking text={text} key={step.id} />
    ) : null;
}

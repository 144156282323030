import { useContext, useEffect, useRef, useState } from 'react';
import { MicError } from '../services/pitch-detection/types';
import { Consumers } from '../services/pitch-detection-consumer/consumers';
import { ServicesContext } from '../services/services.context';

type TryToResumeOrPromptProps = {
    consumerId: Consumers;
    resumeSucceed?: () => void;
    resumeFail?: (micError: MicError) => void;
    needToPrompt?: () => void;
};

export function useTryToResumeOrPrompt({
    consumerId,
    resumeSucceed,
    resumeFail,
    needToPrompt,
}: TryToResumeOrPromptProps) {
    const { pitchDetectionConsumer, preferenceMicStore } =
        useContext(ServicesContext);
    const [isPending, setIsPending] = useState(false);

    const timeoutRef = useRef<number>();

    const tryToResumePitchDetection = async () => {
        const micError = await pitchDetectionConsumer.addConsumer(consumerId);

        if (micError && micError.error === null) {
            if (resumeSucceed) {
                resumeSucceed();
            }
        }
        if (micError && micError.error !== null) {
            if (resumeFail) {
                resumeFail(micError.error);
            }
        }

        pitchDetectionConsumer.removeConsumer(consumerId);
    };

    const execute = async () => {
        setIsPending(true);

        const permissionState = await preferenceMicStore.get();
        switch (permissionState) {
            case 'granted':
            case 'denied':
                await tryToResumePitchDetection();
                break;
            case 'prompt':
                if (needToPrompt) {
                    needToPrompt();
                }
                break;
        }

        timeoutRef.current = window.setTimeout(() => {
            setIsPending(false);
        }, 500);
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                window.clearTimeout(timeoutRef.current);
            }
            pitchDetectionConsumer.removeConsumer(consumerId);
        };
    }, [consumerId]);

    return { execute, isPending };
}

import { useContext, useEffect } from 'react';
import { GuitarNeck } from '../../../guitar-neck/guitar-neck';
import { soundPlayer } from '../../../../../services/sound-player/boot';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import { FindTheIntervalOnTheNeckExerciseContext } from '../../store/context';
import { useResult } from '../../../../../services/exercise/hooks/use-result';
import { useFindTheIntervalOnTheNeckStore } from '../../store/hook';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import {
    DEFAULT_FRET_LENGTH,
    useLeftHanded,
} from '../../../../../services/fretboard';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { PartId } from '../../../../../services/sequence/sequence.constants';
import { AnswerSectionResultCell } from './answer-section-result-cell';

export function AnswerSectionResult() {
    const isVertical = useGuitarNeckVertical();
    const leftHanded = useLeftHanded();

    const fretboard = usePreferencesStore((state) => state.fretboard);

    const config = useFindTheIntervalOnTheNeckStore((state) => state.config);
    const quizMode = useFindTheIntervalOnTheNeckStore(
        (state) => state.quizMode,
    );
    const store = useContext(FindTheIntervalOnTheNeckExerciseContext);
    const question = useQuestion(store);
    const result = useResult(store);

    const correctAnswer = question.correctAnswer;
    const givenRootPosition = question.question.givenRootPosition;

    useEffect(() => {
        function playCorrectAnswerNote() {
            if (result !== null && quizMode === 'app') {
                soundPlayer.playSequence(
                    PartId.FIND_THE_NOTE_ON_THE_NECK,
                    [
                        {
                            type: 'note',
                            position: question.correctAnswer,
                            time: 0,
                            duration: 0.25,
                        },
                    ],
                    fretboard.noteDetails,
                );
            }
        }
        playCorrectAnswerNote();
    }, [result, config, quizMode]);

    return result !== null ? (
        <GuitarNeck
            buildCellsComponent={AnswerSectionResultCell}
            isVertical={isVertical}
            leftHanded={leftHanded}
            numberOfFrets={DEFAULT_FRET_LENGTH}
            tuning={config.tuningInfo.notes}
            additionalCellProps={{
                correctAnswer,
                fretboard,
                givenRootPosition,
            }}
        />
    ) : null;
}

import { Interval, Note, Range } from 'tonal';
import { getNoteDetails } from '../notes/get-note-details';
import { FretboardNoteDetails } from './fretboard.types';

export function getFretboardNoteDetailsByTuningAndFretLength(
    tuning: string[],
    fretLength: number,
): FretboardNoteDetails {
    return tuning.map((note) => {
        const endNote = Note.transpose(
            note,
            Interval.fromSemitones(fretLength),
        );
        const notes = Range.chromatic([note, endNote], { sharps: true });
        return notes.map(getNoteDetails);
    });
}
